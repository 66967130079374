// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;
$grid-gutter-width:     1.5rem;
$grid-gutter-height:    1rem;

// COLORS
$blue:                  #00356b;
$blue-light:            #286dc0;
$blue-lighter:          #96c6ff;
// $blue-lighter2:          #63aaff;
$gray:                  #978d85;
$gray-light:            #ddd;
$gray-dark:             #4a4a4a;
$white:                 #fff;
$white-near:            #f9f9f9;
$black:                 #222;
$black-true:            #000;
$green:                 #5f712d;
$red:                   #bc5319;
$brand-primary:         $blue;
$brand-secondary:       $blue-light;
$body-bg:               $white;
$body-color:            $black;
$text-color:            $body-color;

// Links
$link-color:            $blue-light;
$link-decoration:       underline;
$link-hover-color:      $text-color;
$link-hover-decoration: underline;


// FONTS
$font-family-sans-serif: 'TheSans', "Helvetica Neue", Arial, sans-serif;
$font-family-title:      'YaleNew', Baskerville, "Baskerville Old Face", "Hoefler Text", Garamond, "Times New Roman", serif;
$font-family-base:       $font-family-sans-serif;


$font-size-base: 1rem;
$font-size-md:   .925rem;
$font-size-lg:   1.25rem;
$font-size-sm:   .875rem;
$font-size-xs:   .75rem;

$font-weight-normal: normal;
$font-weight-bold: bold;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.5rem;
$font-size-h2: 2rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.5rem;
$font-size-h5: 1.25rem;
$font-size-h6: 1rem;



// $headings-margin-bottom: ($spacer / 2);
$headings-font-family:   $font-family-title;
$headings-font-weight:   400;
$headings-line-height:   1.2;
$headings-color:         inherit;
