aside.sidebar {
  .widget {
    font-size: $font-size-md;
    margin-bottom: $grid-gutter-width;
    h3 {
      font-size: $font-size-h4;
    }
    &:first-child {
      h3 {
        margin-top: 0;
      }
    }
    &.events-widget {
      h3 {
        padding-bottom: .2em;
        border-bottom: 1px solid $gray-lighter;
      }
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          margin-bottom: 1rem;
          a {
            display: block;
            text-decoration: none;
            line-height: 1.4;
            date {
              display: block;
              color: $gray;
              font-size: $font-size-sm;
            }
          }
        }
      }
    }
    &.latest-newsletter {
      h3 {
        padding-bottom: .2em;
        border-bottom: 1px solid $gray-lighter;
      }
    }
  }
}
