.page-header {
  padding-bottom: $grid-gutter-width;
  @include media-breakpoint-up(md) {
    padding-bottom: $grid-gutter-width*1.5;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: $grid-gutter-width*1.5;
  }
  h1 {
    border-bottom: 1px solid $gray-lighter;
    span {
      color: $brand-secondary;
    }
  }
}
.hero {
  height: 300px;
  background-color: $gray;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-up(md) {
    height: 500px;
  }

/*
  @include media-breakpoint-up(xl) {
    height: 600px;
  }
*/
  .slick-slide {
    div {
      height: 300px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @include media-breakpoint-up(md) {
        height: 500px;
      }
    }
  }
}
