html {
  font-size: 14px;
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}
.main {
  h1,h2,h3 {
    &:first-child {
      margin-top: 0;
    }
  }
}
img {
  @extend .img-fluid;
}
.wrap {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  @include media-breakpoint-up(md) {
    padding-top: $grid-gutter-width*1.5;
    padding-bottom: $grid-gutter-width*1.5;
  }
  @include media-breakpoint-up(lg) {
    padding-top: $grid-gutter-width*2;
    padding-bottom: $grid-gutter-width*2;
  }
}
h1,h2,h3,h4,h5,h6 {
  margin: 2rem 0 1.2rem;
  @include media-breakpoint-up(lg) {
    margin: 2.5rem 0 1.5rem;
  }
}
h2 {
  color: $brand-secondary;
}
h1,h3 {
  color: $brand-primary;
}
h5,h6 {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}
button {
  &:hover {
    cursor: pointer;
  }
}
.table {
  margin: $grid-gutter-width*1.5 0;
}
