.banner {
  @extend .bg-primary;
  color: $white;
  h1 {
    margin: 0;
    line-height: 1;
    .brand {
      display: block;
      margin: $grid-gutter-width/3 auto;
      @include media-breakpoint-up(md) {
        max-width: 500px;
        margin-bottom: $grid-gutter-width/6;
      }
    }
  }
  h2 {
    @extend .d-none;
    @extend .d-md-block;
    color: rgba($white,.65);
    margin: 0 0 $grid-gutter-width*.66;
    text-align: center;
    font-size: $font-size-sm;
    font-style: italic;
  }
  .navbar-toggler {
    margin-top: $grid-gutter-height;
    display: flex;
    margin-left: auto;
    font-size: $font-size-lg;
    color: $white;
  }
  .nav-zone {
    background-color: $brand-secondary;
  }
  .navbar {
    padding: 0;
    .navbar-nav {
      justify-content: space-between;
      width: 100%;
      @include media-breakpoint-up(lg) {
        justify-content: center;

      }
      .nav-item {
        .nav-link {
          text-decoration: none;
          color: $white;
          text-transform: uppercase;
          @include media-breakpoint-up(lg) {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
          }
          @include media-breakpoint-up(xl) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
          &:hover {
            color: $blue-lighter;
          }
        }
        &.current-menu-item,&.current_page_item,&.active {
          .nav-link {
            color: $blue-lighter;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      &.collapse {
        display: none;
        &.show {
          display: block;
        }
      }
/*
      .navbar-nav {
        .nav-item {
          text-align: center;
          &:first-child {
            .nav-link {
              padding-top: 1.25rem;
            }
          }
        }
      }
*/
    }
  }
}
