.home {
  .main {
    @extend .col-md-11;
    @extend .col-xl-9;
/*
    @extend .offset-md-1;
    @extend .offset-xl-2;
    text-align: center;
*/
  }
}
.block-events {
  @extend .bg-blue-trans;
  padding: $grid-gutter-width 0;
  @include media-breakpoint-up(md) {
    padding: $grid-gutter-width*1.5 0;
  }
  h2.events-title {
    margin: 0 0 $grid-gutter-width;
    padding-bottom: .1em;
    border-bottom: 1px solid rgba($brand-primary,.15);
    color: $brand-primary;
    a {
      @extend .btn;
      @extend .btn-sm;
      float: right;
    }
  }
  .event {
    @extend .col-12;
    @extend .col-md-4;
    margin-bottom: $grid-gutter-width*2;
    @include media-breakpoint-up(md) {
      margin-bottom: $grid-gutter-width;
    }
    &:last-child {
      margin-bottom: $grid-gutter-width;
    }
    .thumb {
      display: block;
      margin-bottom: $grid-gutter-width/2;
      background-color: $brand-primary;
      img {
        width: 100%;
//         transition: all .1s ease;
      }
      &:hover {
        img {
          opacity: .85;
//           transform:scale(1.01);
        }
      }
    }
    h3 {
      margin: 0;
      font-size: $font-size-h5;
      a {
        text-decoration: none;
        color: $brand-primary;
        &:hover {
          color: $text-color;
          text-decoration: underline;
        }
      }
    }
    date {
      display: block;
      color: rgba($brand-primary,.6);
      font-size: $font-size-sm;
      text-transform: uppercase;
      margin-bottom: $grid-gutter-width/5;
    }
  }
}
