.content-info {
  @extend .bg-gray-light;
  .footer-navs {
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    @include media-breakpoint-up(md) {
      padding-top: $grid-gutter-width*1.5;
      padding-bottom: $grid-gutter-width*1.5;
    }
    @include media-breakpoint-up(lg) {
      padding-top: $grid-gutter-width*1.75;
      padding-bottom: $grid-gutter-width*1.75;
    }
    h3 {
      margin: 0 0 $grid-gutter-width;
      padding-bottom: .2em;
      border-bottom: 1px solid rgba($text-color,.2);
      color: $text-color;
      font-size: $font-size-h4;
    }
    .nav-footer {
      margin: 0 0 $grid-gutter-width*1.5;
      padding: 0;
      list-style: none;
      column-width: 40%;
      column-gap: $grid-gutter-width*2;
      columns: 2;
      @include media-breakpoint-up(md) {
        columns: 3;
        column-gap: $grid-gutter-width;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width*1.6;
        columns: 4;
      }
      li {
        font-size: $font-size-sm;
        a {
          color: $text-color;
          text-decoration: none;
          &:hover {
            color: $brand-primary;
            text-decoration: underline;
          }
        }
      }
    }
    .credit {
      font-size: $font-size-xs;
      color: rgba($gray-dark,.7);
    }
  }
  .facebook-block {
    @extend .bg-secondary;
    color: $white;
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    text-align: center;
    font-size: $font-size-lg;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    i {
      font-size: $font-size-h3;
      margin: 0 .4em;
      font-weight: normal;
    }
    a {
      display: block;
      color: $white;
      text-decoration: none;
      &:hover {
        color: $gray-light;
      }
    }
  }
  .copyright {
    @extend .bg-primary;
    font-size: $font-size-xs;
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    .yale-link {
      display: block;
      max-width: 75px;
    }
    .year {
      padding-top: $grid-gutter-width;
      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
      @include media-breakpoint-up(lg) {
        padding-top: $grid-gutter-width/2;
      }
      .nav-social {
        margin: 0;
        padding: $grid-gutter-width 0 0;
        list-style: none;
        li {
          display: inline-block;
          font-size: $font-size-base;
          margin-right: .75em;
          line-height: 1;
          a {
            color: $white;
            &:hover {
              color: $blue-lighter;
            }
          }
        }
         @include media-breakpoint-up(md) {
          padding-top: $grid-gutter-width/2;
         }
         @include media-breakpoint-up(lg) {
          padding-top: 0;
          float: right;
          li {
            margin: 0 0 0 .65em;
            font-size: $font-size-lg;
          }
        }

      }
    }
  }
}
