@font-face{
  font-family:"TheSans";
  src:url(../fonts/TheSans_B2_400_.eot);
  src:url(../fonts/TheSans_B2_400_.eot?#iefix) format("embedded-opentype"),
      url(../fonts/TheSans_B2_400_.woff) format("woff"),
      url(../fonts/TheSans_B2_400_.svg#TheSans_B2_400_) format("svg");
  font-weight:normal;
  font-style:normal;
}
@font-face{
  font-family:"TheSans";
  src:url(../fonts/TheSans_B2_400i.eot);
  src:url(../fonts/TheSans_B2_400i.eot?#iefix) format("embedded-opentype"),
      url(../fonts/TheSans_B2_400i.woff) format("woff"),
      url(../fonts/TheSans_B2_400_SemiLightItalic/TheSans_B2_400i.svg#TheSans_B2_400i) format("svg");
  font-weight:normal;
  font-style:italic;
}
@font-face{
  font-family:"TheSans";
  src:url(../fonts/TheSans_B2_700_.eot);
  src:url(../fonts/TheSans_B2_700_.eot?#iefix) format("embedded-opentype"),
      url(../fonts/TheSans_B2_700_.woff) format("woff"),
      url(../fonts/TheSans_B2_700_.svg#TheSans_B2_700_) format("svg");
  font-weight:bold;
  font-style:normal;
}
@font-face{
  font-family:"TheSans";
  src:url(../fonts/TheSans_B2_700i.eot);
  src:url(../fonts/TheSans_B2_700i.eot?#iefix) format("embedded-opentype"),
      url(../fonts/TheSans_B2_700i.woff) format("woff"),
      url(../fonts/TheSans_B2_700_BoldItalic/TheSans_B2_700i.svg#TheSans_B2_700i) format("svg");
  font-weight:bold;
  font-style:italic;
}


@font-face {
  font-family: 'YaleNew';
  src:url(../fonts/yalenew-roman-webfont.eot);
  src:url(../fonts/yalenew-roman-webfont.eot?#iefix) format("embedded-opentype"),
      url(../fonts/yalenew-roman-webfont.woff2) format("woff2"),
      url(../fonts/yalenew-roman-webfont.woff) format("woff"),
      url(../fonts/yalenew-roman-webfont.svg#TheSans_B2_700i) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'YaleNew';
  src:url(../fonts/yalenew-italic-webfont.eot);
  src:url(../fonts/yalenew-italic-webfont.eot?#iefix) format("embedded-opentype"),
      url(../fonts/yalenew-italic-webfont.woff2) format("woff2"),
      url(../fonts/yalenew-italic-webfont.woff) format("woff"),
      url(../fonts/yalenew-italic-webfont.svg#TheSans_B2_700i) format("svg");
  font-weight: normal;
  font-style:italic;
}
@font-face {
  font-family: 'YaleNew';
  src:url(../fonts/yalenew-bold-webfont.eot);
  src:url(../fonts/yalenew-bold-webfont.eot?#iefix) format("embedded-opentype"),
      url(../fonts/yalenew-bold-webfont.woff2) format("woff2"),
      url(../fonts/yalenew-bold-webfont.woff) format("woff"),
      url(../fonts/yalenew-bold-webfont.svg#TheSans_B2_700i) format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'YaleNew';
  src:url(../fonts/yalenew-bolditalic-webfont.eot);
  src:url(../fonts/yalenew-bolditalic-webfont.eot?#iefix) format("embedded-opentype"),
      url(../fonts/yalenew-bolditalic-webfont.woff2) format("woff2"),
      url(../fonts/yalenew-bolditalic-webfont.woff) format("woff"),
      url(../fonts/yalenew-bolditalic-webfont.svg#TheSans_B2_700i) format("svg");
  font-weight: bold;
  font-style:italic;
}
