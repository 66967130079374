.event-item {
  margin-bottom: $grid-gutter-width*2;
  .event-image {
    @extend .col-12;
    @extend .col-md-4;
    @extend .col-xl-3;
    a {
      display: block;
      img {
        margin-bottom: $grid-gutter-width/2;
      }
    }
  }
  .event-detail {
    @extend .col-12;
    @extend .col-md-8;
    @extend .col-xl-9;
    date {
      display: block;
      color: $brand-primary;
      font-size: $font-size-sm;
      margin-bottom: $grid-gutter-width/3;
    }
    h3 {
      margin: 0 0 $grid-gutter-width/2;
      font-size: $font-size-h4;
      a {
        text-decoration: none;
      }
    }
    p {
      font-size: $font-size-md;
    }
  }
}
.single-event {
  .hentry {
    .location {
      margin-bottom: $grid-gutter-width;
      .name {
        @extend h4;
        color: $brand-secondary;
      }
      .adr {
        display: block;
        color: $gray;
        font-size: $font-size-sm;
        a {
          color: $gray;
          text-decoration: none;
          &:hover {
            color: $brand-secondary;
          }
        }
      }
    }
    date {
      display: block;
      color: $brand-primary;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      margin-bottom: $grid-gutter-width;
    }
    .purchase {
      margin-bottom: $grid-gutter-width;
    }
  }
}
