.btn {
  background-color: $brand-secondary;
  border-color: $brand-secondary;
  color: $white;
  font: $font-weight-bold $font-size-sm $font-family-base;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: $white;
    cursor: pointer;
  }
}
