.bg-primary {
  background-color: $brand-primary;
  color: $white;
  a {
    color: $blue-lighter;
  }
}
.bg-secondary {
  background-color: $brand-secondary;
  color: $white;
  a {
    color: $blue-lighter;
  }
}
.bg-blue-lighter {
  background-color: $blue-lighter;
  color: $brand-primary;
  a {
    color: $text-color;
    &:hover {
      color: $brand-primary;
    }
  }
}
.bg-blue-trans {
  background-color: rgba($blue-lighter,.3);
  color: $brand-primary;
  a {
    color: $text-color;
    &:hover {
      color: $brand-primary;
    }
  }
}

.bg-gray-light {
  background-color: $gray-light;
  color: $black;
  a {
    color: $black;
    &:hover {

    }
  }
}
.bg-gray {
  background-color: $gray;
  color: $black;
  a {
    color: $black;
    &:hover {

    }
  }
}
.bg-red {
  background-color: $red;
  color: $white;
  a {
    color: $blue-lighter;
  }
}
.font-awesome {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-variant-caps: normal;
  font-variant-ligatures: normal;
  font-weight: 900;
  height: 16px;
  line-height: 16px;
  text-rendering: auto;
  width: 16px;
  -webkit-font-smoothing: antialiased;
}
